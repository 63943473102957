import React from 'react';
import './Brylanty.scss';
import Text from '../../Text/Text';

export default function Brylanty() {
    return (
        <div className="brylanty-content">
            <div className="brylanty-image">
                <img src="./images/Brylanty.jpeg" alt="Brylanty" />
            </div>
            <div className="brylanty-text">
                <Text 
                    title={'brylanty m&M gold'} 
                    text={'M&M GOLD jest dostawcą diamentów do firm jubilerskich i instytucji w całej Polsce. Kupuj bezpośrednio od międzynarodowego eksperta diamentów. Dostarczamy diamenty inwestycyjne oraz jubilerskie we wszystkich szlifach oraz wielkościach. Gwarantujemy uzyskanie najlepszej ceny na duże certyfikowane brylanty! Brylanty to nie tylko najlepsi przyjaciele każdej kobiety, ale również inwestycja na lata. Zapraszamy do zapoznania się z naszą wyjątkową, brylantową ofertą!'} 
                    showButton={false} 
                    align={'text-left'} 
                    content={''} 
                    path={''} 
                />
            </div>
        </div>
    );
}