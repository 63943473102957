import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Contact from "./pages/Contact/Contact";
import AboutUs from "./pages/AboutUs/AboutUs";
import Home from "./pages/Home/Home";
import Sizes from "./pages/Sizes/Sizes";
import Showrooms from "./pages/Showrooms/Showrooms";
import AboutDiamonds from "./pages/AboutDiamonds/AboutDiamonds";
import Gifts from "./pages/Gifts/Gifts";
import CartCheckout from "./pages/CartCheckout/CartCheckout";
import Cart from "./pages/Cart/Cart";
import FAQ from "./pages/FAQ/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy/Privacy";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import AdminLayout from "./components/Admin/AdminLayout";
import Regulations from "./pages/Regulations/Regulations";
import ProductsTable from "./components/Admin/ProductsTable/ProductsTable";
import Category from "./components/Admin/Category/Category";
import Users from "./components/Admin/User/User";
import Collection from "./components/Admin/Collection/Collection";
import SuperCategory from "./components/Admin/SuperCategory/SuperCategory";
import Types from "./components/Admin/Types/Types";
import { ProtectedRoute } from "./components/Admin/ProtectedRoute/ProtectedRoute";
import Changes from "./components/Admin/Log/Log";
export default function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sizes" element={<Sizes />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/showrooms" element={<Showrooms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/about-diamonds" element={<AboutDiamonds />} />
            <Route path="/gifts" element={<Gifts />} />
            <Route path="/checkout" element={<CartCheckout />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/regulations" element={<Regulations />} />

            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute>
                  <AdminLayout />
                </ProtectedRoute>
              }
            >
              <Route
                path="products"
                element={
                  <ProtectedRoute>
                    <ProductsTable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="log"
                element={
                  <ProtectedRoute role="ADMIN">
                    <Changes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="category"
                element={
                  <ProtectedRoute>
                    <Category />
                  </ProtectedRoute>
                }
              />
              <Route
                path="users"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="collections"
                element={
                  <ProtectedRoute>
                    <Collection />
                  </ProtectedRoute>
                }
              />
              <Route
                path="supercategory"
                element={
                  <ProtectedRoute>
                    <SuperCategory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="types"
                element={
                  <ProtectedRoute>
                    <Types />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}
