import React, { useState } from "react";
import FooterLink from "../FooterLink/FooterLink";
import "./FooterCategory.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FooterCategoryProps {
  title: string;
  items: { text: string; icon?: React.ReactNode }[];
}

const FooterCategory = ({ title, items }: FooterCategoryProps) => {
  const isMobile = useMediaQuery("(max-width:1050px)");
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="footer-category">
      {isMobile ? (
        <Accordion
          expanded={expanded}
          onChange={handleChange}
          className="accordion"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            className="accordionHeader"
          >
            {" "}
            <h4>{title}</h4>
          </AccordionSummary>
          <AccordionDetails className="accordionAnswer">
            {items.map((item, index) => (
              <FooterLink key={index} text={item.text} icon={item.icon} />
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <h4>{title}</h4>
          {items.map((item, index) => (
            <FooterLink key={index} text={item.text} icon={item.icon} />
          ))}
        </>
      )}
    </div>
  );
};

export default FooterCategory;
