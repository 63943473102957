import "./OurPlacesSection.scss";
import Text from "../Text/Text";
import Location from "./Location";
import { useState } from "react";
import { Image } from "antd";

export default function OurPlacesSection() {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const locationData: any[] = [
    {
      location: "GALERIA PÓŁNOCNA",
      address: "ul. Światowida  17",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "45%",
      position_y: "3.3%",
      pin_position_x: "38.2%",
      pin_position_y: "14%",
    },
    {
      location: "CH KLIF",
      address: "ul. Okopowa 58/72",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "47%",
      position_y: "32.3%",
      pin_position_x: "41.2%",
      pin_position_y: "44%",
    },
    {
      location: "CH MODO",
      address: "ul. Łopuszańska 22",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "8.2%",
      position_y: "53%",
      pin_position_x: "34.2%",
      pin_position_y: "68.5%",
    },
    {
      location: "GALERIA PANORAMA",
      address: "ul. Witosa 31",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "65.5%",
      position_y: "67%",
      pin_position_x: "60.8%",
      pin_position_y: "65.6%",
    },
  ];

  const handleLocationClick = (index: any) => {
    console.log(index);
    setSelectedLocation(index);
  };

  const renderContent = () => {
    if (selectedLocation === null) {
      return <p>Wybierz lokalizację, aby zobaczyć szczegóły.</p>;
    }
    const selected = locationData[selectedLocation];
    return (
      <div className="details_container">
        <div className="details_image">
          <Image
            height={"100%"}
            src={selected.img_path}
            alt="Prezentacja salonu w danej lokalizacji"
          />
        </div>
        <div>
          <p>{selected.location}</p>
          <p>
            {selected.address}
            <br></br>
            {selected.addressExt}
            <br></br>
            {selected.tel}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="OurPlacesContainer">
      <div className="Left-side">
        <Text
          title={"Nasze salony"}
          text={
            "Odkryj skarbce M&M Gold najbliżej siebie." +
            "\n" +
            "Dojazd w 12 minut z każdego miejsca w Warszawie." +
            "\n\n" +
            "Kliknij w interesujący Cię salon aby zobaczyć informacje."
          }
          showButton={false}
          align={"text-left"}
          content={"POZNAJ SZCZEGÓŁY"}
          path={""}
        />
        {renderContent()}
      </div>
      <div className="Right-side">
        <div className="map_photo">
          {locationData.map((loc, index) => (
            <Location
              key={index}
              location={loc.location}
              address={loc.address}
              position_x={loc.position_x}
              position_y={loc.position_y}
              pin_position_x={loc.pin_position_x}
              pin_position_y={loc.pin_position_y}
              isSelected={selectedLocation === index}
              onClick={() => handleLocationClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
