import React from 'react';
import './Bestsellers.scss';
import AdjustableButton from '../AdjustableButton/AdjustableButton';
import useBestsellers from '../../hooks/useBestsellers';

const Bestsellers = () => {
    const products = [
        {
            img: '/images/bestsellers_product1.jpg',
            title: 'ROLEX DATEJUST 36 ROSEGOLD/STEEL',
            description: 'Zanurz się w elegancji i prestiżu, dzięki ikonie luksusu i perfekcji zegarmistrzowskiej.',
        },
        {
            img: '/images/bestsellers_product1.jpg',
            title: 'Tytul 2',
            description: 'Opis 2.',
        },
        {
            img: '/images/bestsellers_product1.jpg',
            title: 'Tytul 3',
            description: 'Opis 3.',
        },
    ];

    const { currentIndex, handlePrevClick, handleNextClick } = useBestsellers(products.length);

    return (
        <div className="bestsellers">
            <div className="bestsellers-content">
                <div className="left-section">
                    <h2>BESTSELLERS</h2>
                    <p>Poznaj nasze najlepiej sprzedające się produkty.</p>
                </div>
                <div className="carousel">
                    <div className="side-image left-image">
                        <img src="/images/bestsellers_background2.jpg" alt="Side Product 1" />
                    </div>
                    <button className="carousel-button left-button" onClick={handlePrevClick}>
                        {'<'}
                    </button>
                    <div className="carousel-item">
                        <img src={products[currentIndex].img} alt={`Product ${currentIndex + 1}`} />
                        <div className="carousel-caption">
                            <h3>{products[currentIndex].title}</h3>
                            <p>{products[currentIndex].description}</p>
                            <AdjustableButton
                                text="KUP TERAZ"
                                backgroundColor="transparent"
                                textColor="white"
                                borderColor="white"
                                hoverBackgroundColor="white"
                                hoverTextColor="black"
                                hoverBorderColor="white"
                                href="#"
                            />
                        </div>
                    </div>
                    <button className="carousel-button right-button" onClick={handleNextClick}>
                        {'>'}
                    </button>
                    <div className="side-image right-image">
                        <img src="/images/bestsellers_background1.jpg" alt="Side Product 2" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bestsellers;
