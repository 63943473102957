import React from "react";
import "./FooterImage.scss";

export default function FooterImage() {
  return (
    <div className="FooterImage">
        <div className="FooterImageContent">
            <h1>PRODUKTY M&M GOLD</h1>
            <p>Poczuj pełnię luksusu i elegancji już dziś!
            A to wszystko z produktami M&M Gold.</p>
            <button>ZOBACZ OFETRĘ</button>
        </div>

    </div>
  );
}
