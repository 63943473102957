import React, { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import Footbar from "../Footbar/Footbar";
import Main from "../Main/Main";
import { Children } from "../../interfaces/children";

export default function Page({ children }: Children) {
  return (
    <Fragment>
      <Navbar />
      <Main>{children}</Main>
      <Footbar />
    </Fragment>
  );
}
