import React from "react";
import Page from "../../components/Page/Page";
import CategorySection from "../../components/CategorySection/CategorySection";
import IdeasForGifts from "../../components/IdeasForGifts/IdeasForGifts";
import InvisibleCollection from "../../components/InvisibleCollection/InvisibleCollection";
import JubilersWorldSection from "../../components/JubilersWorldSection/JubilersWorldSection";
import NewProductsSection from "../../components/NewProductsSection/NewProductsSection";
import OfferSection from "../../components/OfferSection/OfferSection";
import WeRecommendSection from "../../components/WeRecommendSection/WeRecommendSection";
import AppointmentSection from "../../components/AppointmentSection/AppointmentSection";
import DiamondKnowledgeSection from "../../components/DiamondKnowledgeSection/DiamondKnowledgeSection";
import CustomMadeSection from "../../components/CustomMadeSection/CustomMadeSection";
import OurPlacesSection from "../../components/OurPlacesSection/OurPlacesSection";
import Sizeer from "../../components/Sizeer/Sizeer";
import RecentlySeen from "../../components/RecentlySeen/RecentlySeen";
import FollowUs from "../../components/FollowUs/FollowUs";
import ForHim from "../../components/ForHim/ForHim";
import Bestsellers from "../../components/Bestsellers/Bestsellers";
import OpeningSection from "../../components/OpeningSection/OpeningSection";

export default function Home() {
  return (
    <Page>
      <OpeningSection />
      <CategorySection />
      <InvisibleCollection />
      <Bestsellers />
      <OfferSection />
      <ForHim />
      <NewProductsSection />
      <AppointmentSection />
      <CustomMadeSection />
      <DiamondKnowledgeSection />
      <IdeasForGifts />
      <JubilersWorldSection />
      <WeRecommendSection />
      <OurPlacesSection />
      <RecentlySeen />
      <Sizeer />
      <FollowUs />
    </Page>
  );
}
