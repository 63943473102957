import React from "react";
import "./FooterLink.scss";

interface FooterLinkProps {
  text: string;
  icon?: React.ReactNode;
}

const FooterLink = ({ text, icon }: FooterLinkProps) => {
  return (
    <p className="footer-link">
      {icon && <span className="icon">{icon}</span>}
      {text}
    </p>
  );
};

export default FooterLink;
