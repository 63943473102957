import BankAccount from "../../components/Contact/BankAccount/BankAccount";
import OpeningSection from "../../components/Contact/OpeningSection/OpeningSection";
import Departaments from "../../components/Contact/Departments/Departments";
import Shop from "../../components/Contact/Shop/Shop";
import Page from "../../components/Page/Page";

export default function Contact() {
    return (
        <Page>
            <OpeningSection />
            <Departaments />
            <BankAccount />
            <Shop />
        </Page>
    );
}