import React from "react";
import "./Footbar.scss";
import FooterSection from "./FooterSection/FooterSection";
import FooterBase from "./FooterBase/FooterBase";
export default function Footbar() {
  return (
    <footer className="footbar-content">
      <FooterSection />
      <FooterBase />
    </footer>
  );
}
