import React, { useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Row,
  Col,
  DatePicker,
} from "antd";
import { IAttribute } from "../../../types/IAttribute";
import moment from "moment";
import dayjs from "dayjs";

interface AttributeFormProps {
  initialValues: IAttribute | undefined;
  onSave: (data: IAttribute) => void;
}
const dateFormat = "DD-MM-YYYY";

export default function AttributeForm({
  onSave,
  initialValues,
}: AttributeFormProps) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values: IAttribute) => {
    // console.log("Otrzymane wartości:", values);
    const formattedValues = {
      ...values,
      productionDate: values.productionDate
        ? dayjs(values.productionDate).format(dateFormat)
        : undefined,
      isRecommended: values.isRecommended ? 1 : 0,
    };
    // console.log("Otrzymane wartości:", formattedValues);
    onSave(formattedValues);
  };

  return (
    <Form
      form={form}
      name="attributeForm"
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues ? initialValues : { isRecommended: false }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Nazwa"
            name="name"
            rules={[{ required: true, message: "Proszę podać nazwę" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Cena"
            name="price"
            rules={[{ required: true, message: "Proszę podać cenę" }]}
          >
            <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Opis"
        name="description"
        rules={[{ required: true, message: "Proszę podać opis" }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Metal" name="metal">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Próba złota" name="goldTest">
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Kamień" name="stone">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Waga kamienia (ct)" name="stoneWeight">
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Zapięcie" name="clasp">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Numer referencyjny" name="referenceNumber">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Średnica (mm)" name="diameter">
            <InputNumber min={0} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Mechanizm" name="mechanism">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Kolor tarczy" name="dialColor">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Stan" name="productCondition">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Zawartość" name="included">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Data produkcji"
            name="productionDate"
            getValueProps={(value) => ({
              value: value ? dayjs(value, dateFormat) : null,
            })}
          >
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Kolor" name="color">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Czystość" name="clarity">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Rodzaj szlifu" name="cutType">
        <Input />
      </Form.Item>

      {/* Zawsze wysyła 1 lub 0 */}
      <Form.Item name="isRecommended" valuePropName="checked">
        <Checkbox>Polecany</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Zatwierdź
        </Button>
      </Form.Item>
    </Form>
  );
}
