import React, { useState, useRef } from "react";
import { Upload, message, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { usePicture } from "../../../hooks/usePicture";
import { IPicture } from "../../../types/IPicture";

const { Dragger } = Upload;

interface PictureUploadProps {
  onSave: (data: IPicture[]) => void;
}

export default function PictureUpload({ onSave }: PictureUploadProps) {
  const { picture } = usePicture();
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadedUids, setUploadedUids] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedExt, setSelectedExt] = useState<string | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [newPictures, setNewPictures] = useState<IPicture[]>();

  const {
    execute: createPicture,
    loading: loadingCreate,
    error: errorCreate,
    value: valueCreate,
  } = picture.create;

  const handleUpload = async (newFiles: any[]) => {
    if (!selectedType || !selectedExt) {
      message.warning("Proszę wybrać typ i rozszerzenie przed dodaniem zdjęć.");
      return;
    }

    let updatedPictures = [...(newPictures || [])];

    for (const fileWrapper of newFiles) {
      const file = fileWrapper.originFileObj;

      if (uploadedUids.includes(fileWrapper.uid)) {
        // console.log(`Plik o uid ${fileWrapper.uid} został już przesłany.`);
        continue;
      }

      try {
        const base64String = await picture.encode(file);

        const newPicture: IPicture = {
          extension: selectedExt,
          type: selectedType,
          data: base64String,
        };

        // console.log(newPicture);

        const response = await createPicture(newPicture);

        setUploadedUids((prevUids) => [...prevUids, fileWrapper.uid]);

        updatedPictures = [...updatedPictures, response];
        setNewPictures(updatedPictures);

        onSave(updatedPictures);

        message.success(`${file.name} został pomyślnie przesłany.`);
      } catch (error) {
        message.error(
          `Wystąpił błąd podczas przesyłania obrazka ${file.name}.`
        );
      }
    }
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file: File) => {
      return false;
    },
    onChange(info: any) {
      const { fileList: newFileList } = info;

      setFileList(newFileList);

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const newFiles = newFileList.filter(
          (fileWrapper: any) => !uploadedUids.includes(fileWrapper.uid)
        );

        if (newFiles.length > 0) {
          handleUpload(newFiles);
          setSelectedType(null);
        }

        debounceTimeout.current = null;
      }, 300);
    },
  };

  return (
    <>
      {(!selectedType || !selectedExt) && (
        <p style={{ lineHeight: "10px" }}>
          Wybierz typ oraz rozszerzenie aby dodać zdjęcia
        </p>
      )}
      <Select
        placeholder="Wybierz typ zdjęcia"
        style={{ width: "90%", lineHeight: "10px" }}
        onChange={(value) => setSelectedType(value)}
        value={selectedType}
      >
        <Select.Option value="background">tło</Select.Option>
        <Select.Option value="przodem">przodem</Select.Option>
        <Select.Option value="bokiem">bokiem</Select.Option>
      </Select>

      <Select
        placeholder="Wybierz rozszerzenie zdjęcia"
        style={{ width: "90%", lineHeight: "10px" }}
        onChange={(value) => setSelectedExt(value)}
        value={selectedExt}
      >
        <Select.Option value=".png">png</Select.Option>
        <Select.Option value=".jpg">jpg</Select.Option>
      </Select>

      <Dragger
        {...props}
        fileList={fileList}
        disabled={!selectedType || !selectedExt}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Kliknij lub przeciągnij zdjęcie produktu, aby je przesłać
        </p>
        <p className="ant-upload-hint">
          Możesz również przesłać wiele plików przeciągając je wszystkie razem.
        </p>
        {loadingCreate && <p>Przesyłanie...</p>}
        {errorCreate && <p style={{ color: "red" }}>Błąd: {errorCreate}</p>}
      </Dragger>
    </>
  );
}
