import React from 'react';
import './OpeningSection.scss';
import IconText from './IconText/IconText';
import AdjustableButton from '../AdjustableButton/AdjustableButton';

export default function OpeningSection() {
    return (
        <section className="opening-section">
            <div className="image-section ">
                <div className="image-content">
                    <h1>TWÓJ PIERWSZY KROK</h1>
                    <h2>W KIERUNKU LUKSUSU I ELEGANCJI</h2>
                    <AdjustableButton
                        text="POZNAJ OFERTĘ"
                        backgroundColor="transparent"
                        textColor="white"
                        borderColor="white"
                        hoverBackgroundColor="white"
                        hoverTextColor="black"
                        hoverBorderColor="white"
                        href="#"
                    />
                </div>
            </div>
            <div className="info-section">
                <IconText
                    icon="diamond"
                    title="DOŚWIADCZENIE"
                    description="Od lat jesteśmy bezpośrednim importerem brylantów na Polski rynek"
                />
                <IconText
                    icon="fact_check"
                    title="PROFESJONALIZM"
                    description="Kolekcja ART Deco wykonywana jest przez najlepszych jubilerów w Polsce"
                />
                <IconText
                    icon="favorite"
                    title="INDYWIDUALNE PODEJŚCIE"
                    description="Szeroka gama personalizowanych produktów na zamówienie"
                />
            </div>
        </section>
    );
}
