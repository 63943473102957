import React, { useState, useEffect, useRef } from "react";
import "./WeRecommendSection.scss";
import ItemCard from "../ItemCard/ItemCard";
import { CustomLeftArrow, CustomRightArrow } from "../CustomArrows/CustomArrows";

export default function WeRecommendSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemWidth = 258; 
  const gap = 30; 
  const totalItemWidth = itemWidth + gap;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const items = [
    {
      imageSrc: "./images/Category-Zegarki.png",
      title: "ZEGAREK ROLEX DATEJUST 36",
      price: "126 000zł",
      content: "Kup Teraz",
      path: "#",
      showButton: true,
    },
    {
      imageSrc: "./images/Category-Pierscionki.jpeg",
      title: "ZEGAREK ROLEX DATEJUST 36",
      price: "126 000zł",
      content: "Kup Teraz",
      path: "#",
      showButton: true,
    },
    {
      imageSrc: "./images/Category-Naszyjniki.jpeg",
      title: "ZEGAREK ROLEX DATEJUST 36",
      price: "126 000zł",
      content: "Kup Teraz",
      path: "#",
      showButton: true,
    },
    {
      imageSrc: "./images/Category-Bransoletki.jpeg",
      title: "ZEGAREK ROLEX DATEJUST 36",
      price: "126 000zł",
      content: "Kup Teraz",
      path: "#",
      showButton: true,
    },
    {
      imageSrc: "./images/Category-Naszyjniki.jpeg",
      title: "ZEGAREK ROLEX DATEJUST 36",
      price: "126 000zł",
      content: "Kup Teraz",
      path: "#",
      showButton: true,
    },
  ];

  const [itemsToShow, setItemsToShow] = useState(0);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        setItemsToShow(Math.floor(carouselWidth / totalItemWidth));
        setCurrentIndex(0); 
      }
    };

    updateItemsToShow();

    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, [totalItemWidth]);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < items.length - itemsToShow) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, items.length - itemsToShow));
    }
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < items.length - itemsToShow - 1;

  return (
    <div className="WeRecommendSection-content">
      <div className="heading">
        <h1>JUBILER GWIAZD POLECA</h1>
      </div>
      <div className="carousel" ref={carouselRef}>
        {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
        <div
          className="carousel-inner"
          style={{ transform: `translateX(-${currentIndex * totalItemWidth}px)` }}
          ref={itemsRef}
        >
          {items.map((item, index) => (
            <ItemCard
              key={index}
              imageSrc={item.imageSrc}
              title={item.title}
              price={item.price}
              content={item.content}
              path={item.path}
              showButton={item.showButton}
            />
          ))}
        </div>
        {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
      </div>
    </div>
  );
}