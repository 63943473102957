import React from 'react';
import './Products.scss';
import Text from '../../Text/Text';

const Products = () => {
    return (
        <div className="products-about-us">
            <div className="section first-section">
                <div className="text-container">
                    <Text
                        title="O M&M GOLD"
                        text="W naszym sklepie znajdą państwo, a także wykonają na zamówienie wymarzony produkt z doradztwem fachowych ekspertów oraz w oparciu o najwyższej klasy materiały dostępne na polskim rynku."
                        showButton={false}
                        align="text-right"
                        content=""
                        path=""
                    />
                </div>
                <div className="image-container">
                    <img src="/images/ProductsAboutUs-1.jpg" alt="M&M Gold Products" />
                </div>
            </div>
            <div className="section second-section">
                <div className="image-container">
                    <img src="/images/ProductsAboutUs-2.jpg" alt="M&M Gold Diamond" />
                </div>
                <div className="text-container">
                    <Text
                        title="INWESTUJ Z M&M GOLD"
                        text="Jesteśmy dostawcą diamentów do firm jubilerskich i instytucji w całej Polsce. Dostarczamy diamenty inwestycyjne oraz jubilerskie we wszystkich szlifach oraz wielkościach. Gwarantujemy uzyskanie najlepszej ceny na duże certyfikowane brylanty!"
                        showButton={false}
                        align="text-left"
                        content=""
                        path=""
                    />
                </div>
            </div>
        </div>
    );
};

export default Products;
