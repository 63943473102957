import React from "react";
import Page from "../../components/Page/Page";
import OpeningSection from "../../components/AboutUs/OpeningSection/OpeningSection";
import Products from "../../components/AboutUs/Products/Products";
import JewelryStar from "../../components/AboutUs/JewelryStar/JewelryStar";
import Details from "../../components/AboutUs/Details/Details";

export default function AboutUs() {
    return (
        <Page>
            <OpeningSection />
            <Products />
            <JewelryStar />
            <Details />
        </Page>
    );
}
