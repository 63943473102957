import React from 'react';
import './JubilersWorldSection.scss';
import Text from '../Text/Text';
import AdjustableButton from '../AdjustableButton/AdjustableButton';

export default function JubilersWorldSection() {
    return (
<div className='jubilerSection'>
    <Text title={'ŚWIAT JUBILERA GWIAZD'} text={' Dołącz do naszego ekskluzywnego świata już teraz i poczuj się jak prawdziwa gwiazda! Zarejestruj się już dziś, aby w pełni korzystać z przywilejów zalogowanych użytkowników '} showButton={false} align={'text-center'} content={''} path={''}/>
    <AdjustableButton text={'DOŁACZ'} href={'#'} />
</div>
    );
}
