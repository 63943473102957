import React from 'react';
import ShopItem from './ShopItem/ShopItem';
import './Shop.scss';

const Shop = () => {
    const shops = [
        {
            images: ['/images/Contact-Shop-polnocna1.jpg', 'path_to_image2.jpg', 'path_to_image3.jpg'],
            title: 'GALERIA PÓŁNOCNA',
            address: 'ul. Światowida 17, 03-144 Warszawa',
            openingHours: [
                { day: 'Pn-Sb', time: '11:00 - 21:00' },
                { day: 'Niedziela handlowa', time: '11:00 - 19:00' }
            ],
            phone: 'ZADZWOŃ +48 515 015 715',
            email: 'WYŚLIJ E-MAIL',
            visitButtonText: 'UMÓW WIZYTĘ W SALONIE',
            visitButtonHref: '#'
        },
        {
            images: ['/images/Contact-Shop-chklif1.jpg', 'path_to_image7.jpg'],
            title: 'CH KLIF',
            address: 'ul. Okopowa 58/72, 01-042 Warszawa',
            openingHours: [
                { day: 'Pn-Sb', time: '11:00 - 21:00' },
                { day: 'Niedziela handlowa', time: '11:00 - 19:00' }
            ],
            phone: 'ZADZWOŃ +48 XXX XXX XXX',
            email: 'WYŚLIJ E-MAIL',
            visitButtonText: 'UMÓW WIZYTĘ W SALONIE',
            visitButtonHref: '#'
        },
        {
            images: ['/images/Contact-Shop-chmodo1.jpg', 'path_to_image8.jpg'],
            title: 'CH MODO',
            address: 'ul. Łopuszańska 38, 02-220 Warszawa',
            openingHours: [
                { day: 'Pn-Sb', time: '11:00 - 19:00' },
                { day: 'Niedziela handlowa', time: '11:00 - 19:00' }
            ],
            phone: 'ZADZWOŃ +48 787 787 777',
            email: 'WYŚLIJ E-MAIL',
            visitButtonText: 'UMÓW WIZYTĘ W SALONIE',
            visitButtonHref: '#'
        },
        {
            images: ['/images/Contact-Shop-panorama1.jpg', 'path_to_image6.jpg'],
            title: 'GALERIA PANORAMA',
            address: 'al. Witosa 31, 01-960 Warszawa',
            openingHours: [
                { day: 'Pn-Sb', time: '11:00 - 21:00' },
                { day: 'Niedziela handlowa', time: '11:00 - 19:00' }
            ],
            phone: 'ZADZWOŃ +48 500 545 550',
            email: 'WYŚLIJ E-MAIL',
            visitButtonText: 'UMÓW WIZYTĘ W SALONIE',
            visitButtonHref: '#'
        },
    ];

    return (
        <div className="shop">
            {shops.map((shop, index) => (
                <ShopItem
                    key={index}
                    images={shop.images}
                    title={shop.title}
                    address={shop.address}
                    openingHours={shop.openingHours}
                    phone={shop.phone}
                    email={shop.email}
                    visitButtonText={shop.visitButtonText}
                    visitButtonHref={shop.visitButtonHref}
                />
            ))}
        </div>
    );
};

export default Shop;
