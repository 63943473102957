import "./ItemCard.scss";
import UnderlinedButton from "../UnderlinedButton/UnderlinedButton";

export default function ItemCard(props: {
  imageSrc: string;
  title: string;
  price: string;
  content: string;
  path: string;
  showButton: boolean;
  variant?: 'small' | 'large'; 
}) {
  const { imageSrc, title, price, content, path, showButton, variant = 'large' } = props;

  return (
    <div className={`itemCard ${variant}`}>
      <img src={imageSrc} alt="Product" className="itemCardImage" />
      <h1>{title}</h1>
      <p>{price}</p>
      {showButton && <UnderlinedButton content={content} path={path} />}
    </div>
  );
}