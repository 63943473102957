import { useState } from 'react';

const useCarousel = (productsLength: number) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        const newIndex = (currentIndex - 1 + productsLength) % productsLength;
        setCurrentIndex(newIndex);
    };

    const handleNextClick = () => {
        const newIndex = (currentIndex + 1) % productsLength;
        setCurrentIndex(newIndex);
    };

    return {
        currentIndex,
        handlePrevClick,
        handleNextClick,
    };
};

export default useCarousel;
