import React from "react";
import "./FooterBase.scss";
import FooterCategory from "../FooterCategory/FooterCategory";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";

const FooterBase = () => {
  const infoItems = [{ text: "Polityka prywatności" }, { text: "Regulamin" }];

  const customerServiceItems = [
    { text: "Kontakt" },
    { text: "Dostawa i płatność" },
    { text: "Sprawdź status zamówienia" },
    { text: "Rozmiary i tabele z rozmiarami" },
  ];

  const aboutUsItems = [{ text: "Nasza historia" }, { text: "Nasze salony" }];

  const contactItems = [
    { text: "+48 787 787 777", icon: <IoCallOutline /> },
    { text: "Galeria północna" },
    { text: "DH klif" },
    { text: "CH modo" },
    { text: "Galeria panorama" },
    { text: "mmgold@mmgold.pl", icon: <CiMail /> },
  ];

  return (
    <div className="footer-items">
      <FooterCategory title="INFORMACJE" items={infoItems} />
      <FooterCategory title="OBSŁUGA KLIENTA" items={customerServiceItems} />
      <FooterCategory title="O NAS" items={aboutUsItems} />
      <FooterCategory title="KONTAKT" items={contactItems} />
    </div>
  );
};

export default FooterBase;
