import React from 'react';
import './Details.scss';
import Text from '../../Text/Text';

const Details = () => {
    return (
        <div className="details">
            <div className="text-container">
                <Text
                    title="DBAŁOŚĆ O DETALE"
                    text="W naszym sklepie znajdą państwo, a także wykonają na zamówienie wymarzony produkt z doradztwem fachowych ekspertów oraz w oparciu o najwyższej klasy materiały dostępne na polskim rynku."
                    showButton={false}
                    align="text-right"
                    content=""
                    path=""
                />
            </div>
            <div className="image-container">
                <img src="../images/DetailsAboutUs.jpg" alt="M&M Gold Packaging" />
            </div>
        </div>
    );
};

export default Details;
