import React from 'react';
import './IconText.scss';
import { OpeningSectionProps } from "../../../interfaces/OpeningSectionProps";

export default function IconText({ icon, title, description }: OpeningSectionProps) {
    return (
        <div className="icon-text">
            <div className={`icon material-symbols-outlined icon-${icon}`}>{icon}</div>
            <div className="text">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
}