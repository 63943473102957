import React from "react";
import "./FooterSection.scss";
import { PiTiktokLogoThin, PiInstagramLogoLight, PiFacebookLogoThin} from "react-icons/pi";
import FooterImage from "../FooterImage/FooterImage";
export default function FooterSection() {
  return (
    <div className="FooterSectionContent">
      <div className="leftSection">
        <h1>M&M Gold</h1>
        <p>
          Nasza biżuteria zachwyca nawet najbardziej wymagających klientów.
          Wśród grona osób, które zakochały się w naszej biżuterii, jest wiele
          gwiazd.
        </p>
            <div className="icons">
            <PiTiktokLogoThin className="icon" />
            <PiInstagramLogoLight className="icon" />
            <PiFacebookLogoThin className="icon" />
            </div>

      </div>
      <div className="rightSection">
        <FooterImage />
      </div>
    </div>
  );
}
