import React from 'react';
import './JewelryStar.scss';
import IconText from '../../OpeningSection/IconText/IconText';
const JewelryStar = () => {
    return (
        <div className="jewelry-star-about-us">
            <div className="image-container">
                <img src="/images/JewelryStarAboutUs.jpg" alt="Jewelry Star" />
            </div>
            <div className="text-container">
                <h1>JUBILER GWIAZD</h1>
                <p>SPRÓBUJESZ RAZ, POKOCHASZ NA ZAWSZE</p>
            </div>
            <div className="info-sections">
                <IconText
                    icon="diamond"
                    title="DOŚWIADCZENIE"
                    description="Od lat jesteśmy bezpośrednim importerem brylantów na Polski rynek"
                />
                <IconText
                    icon="fact_check"
                    title="PROFESJONALIZM"
                    description="Kolekcja ART Deco wykonywana jest przez najlepszych jubilerów w Polsce"
                />
                <IconText
                    icon="favorite"
                    title="INDYWIDUALNE PODEJŚCIE"
                    description="Szeroka gama personalizowanych produktów na zamówienie"
                />
            </div>
        </div>
    );
};

export default JewelryStar;
